import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <h3 {...{
      "id": "single--multi-line"
    }}>{`Single & multi-line`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$field-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet-button:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-ui`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet--light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$field-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet--light:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-ui`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "inline-snippet"
    }}>{`Inline snippet`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet--inline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$field-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet--inline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet--inline:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-ui`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "syntax-colors"
    }}>{`Syntax colors`}</h3>
    <p>{`Carbon has defined a set of accessible syntax colors. View an incontext `}<a parentName="p" {...{
        "href": "https://codepen.io/team/carbon/full/eKMBLw/"
      }}>{`example`}</a>{` on CodePen.`}</p>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet.code`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$code-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet--inline.code`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$code-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <h3 {...{
      "id": "single-line"
    }}>{`Single line`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet--single`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 / 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet--single`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`max-width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`768 / 48`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet--single`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48 / 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-09`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet--single`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "32.20108695652174%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAABMklEQVQY02NgAIJ3G88xPATSh3dcYvj//z8jGH//zvTk+TOmt2/eML1+/Zrp5cuXTK9evQLjp8+eMcHV/f/PkAfU+696FQMYXFRPZaAWgJs1d/Y6hv9AumLxRp7V1656XXn4wP3Bi+eOr1+/snv99o396zev7V++emX/6vVr+xdA+uPbt7bnnj11mXbmbGDFyk2CIDOWVUxnhJu8d/0hRqCBLItXH1C8+PjJzbfPnz98/uz5/WdPnz149fLlg5fPXzwA8Z8/e/YAKHb/xZMnDx68fPnw0I07D7ftPKkF1Mt4pGouC4aXz1oXMd5NnshxNXsy++3gVu5bTpUiNxwrhG+5Vgvedq4WAdHX7ctFbvnU897JmsJ+N2cGx97eFYwYXgaBY141ZIfdqagOhlsMomA2AEhar3zazzmNAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "code snippet style 1",
          "title": "code snippet style 1",
          "src": "/static/ba8b860e83b41107b8e58fdd32ed737d/fb070/code-snippet-style-1.png",
          "srcSet": ["/static/ba8b860e83b41107b8e58fdd32ed737d/d6747/code-snippet-style-1.png 288w", "/static/ba8b860e83b41107b8e58fdd32ed737d/09548/code-snippet-style-1.png 576w", "/static/ba8b860e83b41107b8e58fdd32ed737d/fb070/code-snippet-style-1.png 1152w", "/static/ba8b860e83b41107b8e58fdd32ed737d/c3e47/code-snippet-style-1.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure and spacing measurements for code snippet | px / rem
    </Caption>
    <h3 {...{
      "id": "multi-line-code-snippet"
    }}>{`Multi-line code snippet`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet--multi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`min-height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`288 / 18`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet--multi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`max-height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Varies based on content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet--multi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`max-width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`768 / 48`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet-container`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-top, padding-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet-container`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48 / 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-09`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet-container`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet-button`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.27173913043478%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAB7klEQVQoz62SO2/TUBTHXWdAYmBB8BUYwQtLxcICFYQFiW/AF+iMEBJDRyQkJMRQgQRLJVQeAsqz0A60lUCA4jhJQ/1IEzu279OOE8exD/ZNGqKOiKP707lXuud//0f3SFIeACCJzKDYzxU4HpYZCWRK+JTizGggI0TlyT0J7v6SDjR+nrouTQMoSP8tbq+OX1l8+P3Ec7V5vo3Z2ThKzsT9kZLEmTIcpErAIoXiQMGIK8Ogf/pZRTt3a+3j/L0bD44UtVfb9b8OP1muXOSXFlpotB0wf+upbXfBMluClrUPvoeAswBYTsjD1GIhfN41tLXlt8eL2qezLW8gXwi+43Sh6XZBUytZvdEAVVWhVq8JdF0Hw9DBdpxckKd2PIAdSrSlrHdUmHGcuWnLm3gs+IbiiwZCsNdsDqtaNdE0Lel0OgnGOKGUJoTghFCSRGEYt6IebBNcuRPgsaA9I7jRQULwdZuUySgD7Pnguh50uy4MBjFAlq8DUoB+LwI8HMHWvm2uPHkvWl6ZbXl9m4m8+oGdNB1+hePgMqdRmeGwTHyeE0wyL+Mcc9e8tPmjeu3VjnqhtvhIfMry4bF58ZX885Qcg0Mjt/5t4vALlQyHlRjipdyJgKCCYEruulSr75XA78lDL5aXbt4XtY8nDv8AAP6b5xasMgsAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing measurements for multi-line snippet",
          "title": "Structure and spacing measurements for multi-line snippet",
          "src": "/static/90a846ed69a762e56cd39bb9f2f663cc/fb070/code-snippet-style-2.png",
          "srcSet": ["/static/90a846ed69a762e56cd39bb9f2f663cc/d6747/code-snippet-style-2.png 288w", "/static/90a846ed69a762e56cd39bb9f2f663cc/09548/code-snippet-style-2.png 576w", "/static/90a846ed69a762e56cd39bb9f2f663cc/fb070/code-snippet-style-2.png 1152w", "/static/90a846ed69a762e56cd39bb9f2f663cc/c3e47/code-snippet-style-2.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure and spacing measurements for multi-line snippet | px / rem
    </Caption>
    <h3 {...{
      "id": "inline-code-snippet"
    }}>{`Inline code snippet`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet--inline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet--inline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Varies based on content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet--inline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-radius`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--snippet--inline code`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-right, padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`\$spacing-03`}</td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "28.396739130434785%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAAAzElEQVQY02NgQAMyE3vh7P///zP8//OX8dKNG4xP791jBPFBIH31SgbiQG0lmGKb1MfAEBrAiE3JtMVrwOLGGSXEG8jg68nA0t/DNfXIYb1DFy4YPrp/X/3clSu6TYcOKINd/uQHA8OiJuIcOef+PTCt/f8/05nbt/3v3b2beufOnfjrN2+mHTxxzgEktxvo9atqqcS7UP3ta5xKztbMZgLR5zSJNHD961dgpur/fwynb91ivnvnDjPQhczXr15j3vPpJTj8LjQvxGkEAF8oW2BOBBM0AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing measurements for inline snippet",
          "title": "Structure and spacing measurements for inline snippet",
          "src": "/static/a3df25c48a19f2a7144c329367eeb60d/fb070/code-snippet-style-3.png",
          "srcSet": ["/static/a3df25c48a19f2a7144c329367eeb60d/d6747/code-snippet-style-3.png 288w", "/static/a3df25c48a19f2a7144c329367eeb60d/09548/code-snippet-style-3.png 576w", "/static/a3df25c48a19f2a7144c329367eeb60d/fb070/code-snippet-style-3.png 1152w", "/static/a3df25c48a19f2a7144c329367eeb60d/c3e47/code-snippet-style-3.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure and spacing measurements for inline code snippet | px / rem
    </Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      